import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, renderContent } from '../../utils/helpers'
import {
  AdditionalNotes,
  AdditionalHealthProblems,
  RelevantInfo,
} from './AdditionalInformationFields'
import MiscellaneousQuestion from './AdditionalQuestionsMisc'
import AdditionalRelatives from './AdditionalRelatives'

const AdditionalInformation = () => {
  const config =
    useSelector((state) => state.configuration.sectionConfig['additional-info']?.questions) || []
  const localization = useLocalization()
  const renderMap = {
    additionalInfoShare: (
      <AdditionalNotes key="ai" label={getLabel(localization, 'additionalInformation')} />
    ),
    healthProblems: (
      <AdditionalHealthProblems
        key="ahp"
        label={getLabel(localization, 'additionalHealthProblems')}
      />
    ),
    relevantInfo: <RelevantInfo key="ri" label={getLabel(localization, 'relevantInfo')} />,
    additionalRelativeConditions: <AdditionalRelatives key="ar" />,
    additionalInfoFamily: (
      <MiscellaneousQuestion
        key="aif"
        questionId="additional-info-family"
        label={getLabel(localization, 'answerInformation.additional-info-family')}
      />
    ),
    additionalInfoQuestions: (
      <MiscellaneousQuestion
        key="aiq"
        questionId="additional-info-questions"
        label={getLabel(localization, 'answerInformation.additional-info-questions')}
      />
    ),
  }
  const content = []

  renderContent({ renderMap, config, content })

  RemoveLoadingHook()

  return <Container>{content}</Container>
}

export { AdditionalInformation }
