import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import Chooser from '../../widgets/Chooser'

const PRONOUN = {
  HE: 'he',
  SHE: 'she',
  THEY: 'they',
  OTHER: 'other',
  UNKNOWN: 'unknown',
  PREFER_NOT_TO_ANSWER: 'preferNotToAnswer',
}

const Pronouns = ({ id }) => {
  const localization = useLocalization()
  const pronoun = useSelector((state) => get(state, [...pathToPerson(id), 'pronoun']))
  const { setPersonPronoun } = useActions()

  return (
    <Chooser
      label="What is your preferred pronoun?"
      selectedValue={pronoun}
      onChange={(value) => setPersonPronoun({ id, value })}
      options={[
        [PRONOUN.HE, getLabel(localization, `pronoun.type.${PRONOUN.HE}`)],
        [PRONOUN.SHE, getLabel(localization, `pronoun.type.${PRONOUN.SHE}`)],
        [PRONOUN.THEY, getLabel(localization, `pronoun.type.${PRONOUN.THEY}`)],
        [PRONOUN.OTHER, getLabel(localization, `pronoun.type.${PRONOUN.OTHER}`)],
        [PRONOUN.UNKNOWN, getLabel(localization, `pronoun.type.${PRONOUN.UNKNOWN}`)],
        [
          PRONOUN.PREFER_NOT_TO_ANSWER,
          getLabel(localization, `pronoun.type.${PRONOUN.PREFER_NOT_TO_ANSWER}`),
        ],
      ]}
    />
  )
}

export default Pronouns
