import { FormControl, FormLabel } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import IntegerDetails from '../../widgets/IntegerDetails'

export default function Pregnancy({ id }) {
  const { setPersonLifeStatus, addUnbornChild, removePerson, setPersonPropertiesIsPresent } =
    useActions()
  const pregnantProperty = useSelector((state) => {
    const properties = get(state, [...pathToPerson(id), 'properties']) || []

    return properties.find(({ type }) => type === 'isPregnant')
  })
  const baby =
    useSelector((state) => {
      const persons = state.questionnaire.persons

      return Object.values(persons).find(
        (person) => person.lifeStatus?.lifeStatusOption === 'unborn',
      )
    }) || {}
  const { lifeStatus: { gestationAge = '' } = {} } = baby

  return (
    <ConditionalSection
      label="Are you or your partner currently pregnant?"
      conditionState={pregnantProperty?.isPresent || ''}
      showWhenCondition={'Y'}
      orderSchema={['Y', 'N', 'U']}
      onConditionStateChange={(value) => {
        if (value !== pregnantProperty?.isPresent) {
          setPersonPropertiesIsPresent({ id, type: 'isPregnant', value })
          if (value === 'Y') {
            addUnbornChild({
              id,
              gestationAge,
              targetPersonArray: ['proband'],
            })
          } else {
            removePerson({ personId: baby.id })
          }
        }
      }}
    >
      <FormControl>
        <FormLabel>How many weeks pregnant?</FormLabel>
        <IntegerDetails
          value={gestationAge}
          onChange={(value) => {
            setPersonLifeStatus({
              id: baby.id,
              type: 'gestationAge',
              value,
            })
          }}
        />
      </FormControl>
    </ConditionalSection>
  )
}
