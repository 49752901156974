import { get, kebabCase } from 'lodash'
import React from 'react'
import {
  AdditionalInformation,
  Conclusion,
  Instructions,
  Introduction,
  Parents,
  RelativeLinkingQuestions,
} from '../steps'
import { Consent } from '../steps/Consent'
import GeneralHealthQuestions from '../steps/GeneralHealth'
import GeneticTesting from '../steps/GeneticTesting'
import { HeartHealthHistory } from '../steps/HeartHealthHistory'
import MedicalHistoryQuestions from '../steps/MedicalHistory'
import Person from '../steps/Person'
import PregnancyReproduction from '../steps/PregnancyReproduction/index.jsx'
import { RelativeDeathDetails } from '../steps/RelativeDeathDetails.jsx'
import cancerRelativeQuestions from '../utils/cancerRelativeQuestions'
import conditionRelativeQuestions from '../utils/conditionRelativeQuestions'
import { renderSubSection } from '../utils/helpers'
import { PersonFieldPaths, PersonProperties } from '../utils/QuestionnaireStateManager'

function questionnaireMap({
  configuration,
  renderProbandFamilySteps,
  renderMotherSteps,
  renderFatherSteps,
  renderFolder,
}) {
  const yourInformationConfig = configuration['your-information']?.subSectionConfig || {}
  const heartHealthConfig = configuration['heart-health-section']?.subSectionConfig || {}
  const heartHealthSubQuestions = (key) => heartHealthConfig[kebabCase(key)]?.questions

  const renderMap = {
    introduction: (
      <Introduction key="introduction" navId="introduction" navTitle="Introduction" icon="notes" />
    ),
    instructions: (
      <Instructions key="instructions" navId="instructions" navTitle="Instructions" icon="list" />
    ),
    parents: <Parents key="parents" navId="parents" navTitle={'Parents'} icon="people" />,
    yourInformation: renderFolder(
      'yourInformation',
      'Your Information',
      renderSubSection({
        renderMap: {
          demographics: (
            <Person
              key="probandDemographics"
              navId="probandDemographics"
              navTitle="Demographics"
              icon="person"
              targetPerson="proband"
              type="proband"
            />
          ),
          'general-health': (
            <GeneralHealthQuestions
              key="generalHealth"
              navId="generalHealth"
              navTitle="General Health"
              icon="person"
              config={get(yourInformationConfig, ['general-health', 'questions'])}
            />
          ),
          'medical-history': (
            <MedicalHistoryQuestions
              key="medicalHistory"
              navId="medicalHistory"
              navTitle="Medical History"
              icon="person"
              config={get(yourInformationConfig, ['medical-history', 'questions'])}
            />
          ),
          'genetic-testing': (
            <GeneticTesting
              key="probandGeneticTesting"
              navId="probandGeneticTesting"
              navTitle="Genetic Testing"
              icon="person"
              config={get(yourInformationConfig, ['genetic-testing', 'questions'])}
            />
          ),
          'pregnancy-reproduction': (
            <PregnancyReproduction
              key="pregnancy-reproduction"
              navId="pregnancy-reproduction"
              icon="baby"
              navTitle="Pregnancy and Reproduction"
              config={get(yourInformationConfig, ['pregnancy-reproduction', 'questions'])}
            />
          ),
        },
        config: yourInformationConfig,
      }),
    ),
    yourFamily: renderProbandFamilySteps(),
    mothersFamily: renderMotherSteps(),
    fathersFamily: renderFatherSteps(),
    heartHealthSection: renderFolder(
      'heartHealthSection',
      'Heart Health History',
      renderSubSection({
        renderMap: {
          probandHeartHealth: (
            <HeartHealthHistory
              key="heartHealth"
              navId="heartHealth"
              navTitle="Your Heart Health"
              icon="heart"
              config={heartHealthSubQuestions('probandHeartHealth')}
            />
          ),
          relativesHeartConditions: (
            <RelativeLinkingQuestions
              key="heartConditions"
              navId="heartConditions"
              navTitle="Heart Disease"
              icon="heart"
              label="Do any of your relatives have a known heart disease or heart condition?"
            />
          ),
          relativesCardiacDevice: (
            <RelativeLinkingQuestions
              key="cardiacDevice"
              navId="cardiacDevice"
              navTitle="Cardiac Device"
              icon="heart"
              label="Have any of your family members required a
              pacemaker or implantable defibrillator (ICD)?"
            />
          ),
          relativesEpilepsy: (
            <RelativeLinkingQuestions
              key="epilepsy"
              navId="epilepsy"
              navTitle="Epilepsy or Seizures"
              icon="people"
              label="Do any of your relatives have epilepsy or seizures?"
            />
          ),
          relativesHearingLoss: (
            <RelativeLinkingQuestions
              key="hearingLoss"
              navId="hearingLoss"
              navTitle="Hearing Loss"
              icon="people"
              label="Do any of your relatives have hearing loss?"
            />
          ),
          relativeDeathDetails: (
            <RelativeDeathDetails
              key="relativeDeathDetails"
              navId="relativeDeathDetails"
              navTitle="Relative Death Details"
              icon="people"
              config={heartHealthSubQuestions('relativeDeathDetails')}
            />
          ),
        },
        config: heartHealthConfig,
      }),
    ),
    cancerRelatedQuestion: renderFolder(
      'cancerRelativeQuestions',
      'Cancer Related Questions',
      relativeQuestionReducer(cancerRelativeQuestions, 'cancerRelatedQuestion'),
    ),
    medicalConditions: renderFolder(
      'conditionRelativeQuestions',
      'Medical Conditions',
      relativeQuestionReducer(conditionRelativeQuestions, 'medicalConditions'),
    ),
    geneticTesting: (
      <RelativeLinkingQuestions
        key="genetic-testing"
        navId={PersonFieldPaths.GENETIC_TESTING}
        icon="people"
        navTitle="Genetic Testing"
        propertyPath={PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE}
        propertyType={PersonFieldPaths.GENETIC_TESTING}
        personProperty={PersonFieldPaths.GENETIC_TESTING_PERFORMED}
        otherQuestions
      />
    ),
    genderIdentity: (
      <RelativeLinkingQuestions
        key="gender-identity"
        navId={PersonFieldPaths.GENDER_IDENTITY}
        icon="people"
        navTitle="Gender Identity"
        propertyPath={PersonFieldPaths.GENDER_IDENTITY}
        propertyType={PersonFieldPaths.PROPERTIES}
        personProperty={PersonProperties.HAS_DIFFERENT_GENDER_IDENTITY}
        otherQuestions
      />
    ),
    additionalInfo: (
      <AdditionalInformation
        key="additional-information"
        navId="additional-information"
        navTitle={'Additional Information'}
        icon="notes"
      />
    ),
    consent: <Consent key="consent" navId="consent" navTitle={'Consent'} icon="notes" />,
    conclusion: (
      <Conclusion key="conclusion" navId="conclusion" navTitle={'Conclusion'} icon="completed" />
    ),
  }

  const steps = []

  for (const key in renderMap) {
    if (configuration[kebabCase(key)]?.enable) {
      steps.push(renderMap[key])
    }
  }

  return steps

  function relativeQuestionReducer(folderQuestions, configId) {
    const subSections = configuration[kebabCase(configId)]?.subSectionConfig || {}
    const relativeLinkQuestions = []
    folderQuestions.forEach(
      ({
        id,
        configId,
        title,
        propertyPath,
        propertyType,
        personProperty,
        otherQuestions,
        label,
      }) => {
        if (
          Object.keys(subSections).find((key) => key === configId) &&
          subSections[configId]?.enable
        ) {
          relativeLinkQuestions.push(
            <RelativeLinkingQuestions
              key={id}
              navId={id}
              configId={configId}
              icon="people"
              navTitle={title}
              propertyPath={propertyPath}
              propertyType={propertyType}
              personProperty={personProperty}
              otherQuestions={otherQuestions}
              label={label}
            />,
          )
        }
      },
    )

    return !relativeLinkQuestions.length ? null : relativeLinkQuestions
  }
}

export default questionnaireMap
